const getPageNameByPathname = (url) => {
    let pageName = "";

    if (url.indexOf("/dashboard") !== -1) pageName = "Dashboard";
    if (url.indexOf("/daily-report") !== -1) pageName = "Daily Report";
    if (url.indexOf("/daily-ctg-pkg-report") !== -1) pageName = "Category Package Report";
    if (url.indexOf("/intage-flash") !== -1) pageName = "Intage OTC Flash Report";
    if (url.indexOf("/intage-flash-vm") !== -1) pageName = "Intage VM Flash Report";
    if (url.indexOf("/intage") !== -1) pageName = "Intage OTC Dashboard";

    if (url.indexOf("/hq-progress") !== -1) pageName = "Region Progress";
    if (url.indexOf("/district-route-progress") !== -1) pageName = "District - Route Progress";
    if (url.indexOf("/sku-deepdive") !== -1) pageName = "Product Breakdown";
    if (url.indexOf("/area-deepdive") !== -1) pageName = "Area Breakdown";
    if (url.indexOf("/progress-ranking") !== -1) pageName = "Progress Ranking";

    if (url.indexOf("/sovi") !== -1) pageName = "SOVI";
    if (url.indexOf("/poc") !== -1) pageName = "PoC";

    return pageName
}

/* Page Event */
(pageChange = () => {
    $(document).on('page_change', '', function (e) {
        e.preventDefault();
        const name = "Page View";
        const data = {
            "page": getPageNameByPathname(location.pathname),
            "url": location.pathname
        };
        console.log(name, data);
        mixpanel.track(name, data);
    });
})();

/* Custom Event */
(trackRadioGroup = () => {
    $(document).on('change', 'input[type="radio"]', function (e) {
        e.preventDefault();
        const name = "Radio Group changed";
        const data = {
            "page": getPageNameByPathname(location.pathname),
            "url": location.pathname,
            "title": "Display",
            "section": $(this).parents(".card").find('.card-header h3').text(),
            "value": $(this).val()
        };
        console.log(name, data);
        mixpanel.track(name, data);
    });
})();

(trackCheckbox = () => {
    $(document).on('change', 'input[type="checkbox"]', function (e) {
        e.preventDefault();
        const name = "Checkbox changed";
        const value = [];
        $(this).parents(".items").find('input:checked').each(function(){
            value.push($(this).next('label').text())
        })
        const data = {
            "page": getPageNameByPathname(location.pathname),
            "url": location.pathname,
            "title": "Display Makers",
            "section": $(this).parents(".card").find('.card-header h3').text(),
            "value": value.join(" ")
        };
        console.log(name, data);
        mixpanel.track(name, data);
    });
})();

// Note: We should have no native dropdown now
(trackNativeDropdown = () => {
    $(document).on('change', 'select', function (e) {
        if (!$(this).parents('.js-salesdate-section').length) {
            e.preventDefault();
            const name = "Select dropdown changed";
            const data = {
                "page": getPageNameByPathname(location.pathname),
                "url": location.pathname,
                "section": $(this).parents(".card").find('.card-header h3').text(),
                "title": $(this).parents(".ng-section").find('b').text(),
                "value": $(this).val()
            };
            console.log(name, data);
            mixpanel.track(name, data);
        }
    });
})();

(trackNgSelectDropdown = () => {
    // Fix dropdown can't get section and title
    $(document).on('click', '.ng-select-single, .ng-select-multiple', function (e) {
        if (!$(e.target).closest('.sense-multi-dropdown, .sense-single-dropdown').length) {
            e.preventDefault();
            const section = $(this).parents(".card").find('.card-header h3').text();
            const title = $(this).parents(".ng-section").find('b').text();
            $(this).find(".ng-option").attr("section", section);
            $(this).find(".ng-option").attr("data-title", title);
        }
    });

    // Note: This cover both single and multiple dropdown
    // Note: this is NOT tracking the value AFTER Aggregate
    $(document).on('click', '.ng-dropdown-panel .ng-option', function (e) {
        if (!$(e.target).closest('.sense-multi-dropdown, .sense-single-dropdown').length) {
            e.preventDefault();
            const name = "Select dropdown changed";
            const data = {
                "page": getPageNameByPathname(location.pathname),
                "url": location.pathname,
                "section": ($(this).attr("section") === undefined) ? $(this).parents(".card").find('.card-header h3').text() : $(this).attr("section"),
                "title": ($(this).attr("data-title") === undefined) ? $(this).parents(".ng-section").find('b').text() : $(this).attr("data-title"),
                "value": ($(this).parents().length < 4) ? $(this).parents(".ng-dropdown-panel").find('.ng-option-marked').text().trim() : $(this).parents(".ng-dropdown-panel").find('.ng-option-selected').text().trim(),
            };
            console.log(name, data);
            mixpanel.track(name, data);
        }
    });
})();

(trackNgSelectDropdownTags = () => {
    $(document).on('ngSelectChange', '', function (e) {
        if (!$(e.target).closest('.sense-multi-dropdown, .sense-single-dropdown').length) {
            e.preventDefault();
            const name = "Select dropdown changed";
            const data = {
                "page": getPageNameByPathname(location.pathname),
                "url": location.pathname,
                "section": "Sankey Summary",
                "title": "Select Key",
                "value": Object.values(e.originalEvent.detail).map(obj=>obj.name).join(", "),
            };
            console.log(name, data);
            mixpanel.track(name, data);
        }
    });
})();

(trackDatePicker = () => {
    $(document).on('dateSelectChange', '', function (e) {
        e.preventDefault();
        if(!e.originalEvent.detail.isDateRange){
            const { year, month, day } = e.originalEvent.detail;
            const name = "Date Picker Changed";
            const data = {
                "page": getPageNameByPathname(location.pathname),
                "url": location.pathname,
                "date": `${year}-${month}-${day}`
            };
            console.log(name, data);
            mixpanel.track(name, data);
        }else{
            const dateRange = e.originalEvent.detail.selectedSalesDate;
            const name = "Date Range Picker Changed";
            const data = {
                "page": getPageNameByPathname(location.pathname),
                "url": location.pathname,
                "date": dateRange?.length >= 2 ? `${dateRange[0]} to ${dateRange[1]}` : `${dateRange[0]} to ${dateRange[0]}`
            };
            console.log(name, data);
            mixpanel.track(name, data);
        }
    });
})();


(trackDownloadButton = () => {
    $(document).on('click', '.dropdown .dropdown-toggle.btn, .download-button-tracking', function (e) {
        e.preventDefault();
        const name = "Download Button Clicked";
        const data = {
            "page": getPageNameByPathname(location.pathname),
            "url": location.pathname,
            "section": $(this).attr('data-section') || $(this).parents(".card").find('.card-header h3').text(),
            "text": $(this).text()
        };
        console.log(name, data);
        mixpanel.track(name, data);
    });
})();

(trackDownloadMenuSubButton = () => {
    $(document).on('click', '.dropdown .dropdown-menu button', function (e) {
        e.preventDefault();
        const name = "Download Menu Sub Button Clicked";
        const data = {
            "page": getPageNameByPathname(location.pathname),
            "url": location.pathname,
            "section": $(this).parents(".card").find('.card-header h3').text(),
            "text": $(this).text()
        };
        console.log(name, data);
        mixpanel.track(name, data);
    });
})();


(trackDownloadMenuSubButton = () => {
    $(document).on('click', '.search-button-container .btn-primary', function (e) {
        e.preventDefault();
        const name = "Apply Button Clicked";
        const data = {
            "page": getPageNameByPathname(location.pathname),
            "url": location.pathname,
            "section": $(this).parents(".card").find('.card-header h3').text(),
            "text": $(this).text()
        };
        console.log(name, data);
        mixpanel.track(name, data);
    });
})();